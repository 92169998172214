@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

:root {
  --header-height: 3rem;
  --hue-color: 190;
  --first-color: hsl(var(--hue-color), 24%, 32%);
  --first-color-alt: hsl(var(--hue-color), 24%, 28%);
  --text-color-home: rgb(234, 233, 233);
  --text-color-body: #262626;
  --title-color: hsl(var(--hue-color), 4%, 15%);
  --text-color-light: hsl(var(--hue-color), 4%, 55%);
  --body-font: "Poppins", sans-serif;
  --biggest-font-size: 2.5rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  --font-medium: 400;
  --font-semi-bold: 500;
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-25: 1.25rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;

  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 968px) {
  :root {
    --biggest-font-size: 2.75rem;
    --h1-font-size: 2rem;
    --h2-font-size: 1.75rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 3rem 0 0 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

h1,
h2,
h3 {
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: var(--text-color-home);
}

button {
  cursor: pointer;
  border: none;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  outline: none;
}

form {
  padding-left: 2rem;
  padding-right: 2rem;
}

.button {
  display: inline-block;
  background-color: var(--first-color);
  color: #fff;
  padding: 1rem 1.75rem;
  border-radius: 0.5rem;
  font-weight: var(--font-medium);
  transition: 0.3s;
}

.button:hover {
  background-color: var(--first-color-alt);
}

.button__icon {
  transition: 0.3s;
}

.button:hover .button__icon {
  transform: translateX(0.25rem);
}

.button--flex {
  display: inline-flex;
  align-items: center;
  column-gap: 0.5rem;
}

.section {
  padding: 4.5rem 0 2.5rem;
}

.container {
  max-width: 968px;
  margin-left: var(--mb-1);
  margin-right: var(--mb-1);
}

.grid {
  display: grid;
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: transparent;
  padding-top: 1rem;
}

.section__title {
  font-size: var(--h1-font-size);
  margin-bottom: 2rem;
}

.section__title-center {
  text-align: center;
}

.section__subtitle {
  font-size: var(--h3-font-size);
  margin-bottom: 4rem;
}

.nav {
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__logo,
.nav__toggle,
.nav__close {
  color: #fff;
}

.nav__logo {
  display: flex;
  align-items: center;
  font-weight: var(--font-semi-bold);
  column-gap: 0.5rem;
}

.nav__logo img {
  width: 30px;
  height: 30px;
  border-radius: 360px;
}

.nav__toggle {
  font-size: 1.2rem;
  cursor: pointer;
}

.nav__menu {
  position: relative;
}

.logo {
  font-size: var(--h1-font-size);
}

@media screen and (max-width: 767px) {
  .nav__menu {
    position: fixed;
    -webkit-backdrop-filter: blur(32px);
    background-color: hsla(180, 12%, 8%, 0.8);
    backdrop-filter: blur(32px);
    border-left: 2px solid var(--first-color-gray);
    top: 0;
    right: -100%;
    width: 70%;
    height: 100%;
    box-shadow: -1px 0 4px hsla(var(--hue-color), 64%, 15%, 0.15);
    padding: 3rem;
    transition: 0.4s;
  }
}

.nav__list {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
}

.nav__link {
  color: var(--text-color-home);
  font-weight: var(--font-semi-bold);
  text-transform: uppercase;
}

.nav__link:hover {
  color: var(--text-color-body);
}

.nav__close {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  font-size: 1.5rem;
  /* color: var(--title-color); */
  cursor: pointer;
}

.show-menu {
  right: 0;
}

.scroll-header {
  background-color: #fff;
  box-shadow: 0 0 4px hsla(var(--hue-color), 64%, 15%, 0.15);
}

.scroll-header .nav__logo,
.scroll-header .nav__toggle {
  color: var(--text-color-body);
}

.colored-header {
  color: var(--text-color-body);
}

.active-link {
  position: relative;
  color: var(--text-color);
}

.active-link::before {
  content: "";
  position: absolute;
  background-color: var(--text-color);
  width: 100%;
  height: 2px;
  bottom: -0.75rem;
  left: 0;
}

.more-nav {
  display: flex;
  justify-content: end;
}

.hero {
  display: flex;
  flex-direction: row;
  height: 100vh;
  justify-content: center;
  align-items: flex-start;
  padding: 2.5rem;
}

.hero__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center;
  z-index: -1;
}

.welcome__container {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.welcome__title {
  margin-bottom: 1rem;
}

.welcome__subtitle {
  font-weight: var(--font-medium);
}

.welcome__title,
.welcome__subtitle {
  color: var(--text-color-home);
}

.welcome__info {
  color: var(--text-color-home);
  padding: 1.75rem 1.75rem;
  background-color: rgb(0, 0, 0, 0.63);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: center;
  gap: 1.5rem;
  position: absolute;
  bottom: 0;
  right: 0;
}

.welcome__info h3 {
  font-size: var(--normal-font-size);
  inline-size: 150px;
  overflow-wrap: break-word;
}

.welcome__info p {
  font-size: var(--smaller-font-size);
  inline-size: 150px;
  overflow-wrap: break-word;
}

.about__content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about__blocks {
  margin-top: 5rem;
}

.about__content-1,
.about__content-2 {
  flex-direction: column-reverse;
  margin-bottom: 4rem;
  padding: 0 1rem 0 1rem;
}

.about__image {
  width: 270px;
}

.about__image-2 {
  width: 220px;
}

.about__information {
  padding: 1rem;
}

.about__information h3 {
  font-size: 1.5em;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}

.about__information p {
  line-height: 1.75rem;
  overflow-wrap: break-word;
  inline-size: 100%;
  text-align: center;
}

.study__posts {
  justify-content: center;
  grid-template-rows: 1fr;
}

.study__post {
  padding: 1rem;
  border-radius: 1rem;
  margin-bottom: 2rem;
}

.study__post iframe {
  border-radius: 1rem;
  border: none;
  width: 275px;
  height: 215px;
}

.study__post:hover {
  box-shadow: 0 12px 16px hsl(228deg, 66%, 45%, 0.1);
}

.contact__title {
  margin-bottom: 4rem;
}

.contact__container {
  column-gap: 3.5rem;
  row-gap: 3rem;
}

.contact__data {
  display: grid;
  row-gap: 2rem;
  justify-content: center;
  text-align: center;
}

.contact__subtitle {
  font-size: var(--normal-font-size);
  font-weight: var(--font-semi-bold);
  color: var(--text-color-body);
  margin-bottom: var(--mb-0-5);
}

.contact__description {
  display: inline-flex;
  align-items: center;
  column-gap: 0.5rem;
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.contact__icon {
  font-size: 1.25rem;
}

.contact__inputs {
  display: grid;
  row-gap: 3rem;
  margin-bottom: var(--mb-2-5);
}

.contact__content {
  position: relative;
  height: 3rem;
  border-bottom: 1px solid var(--text-color-light);
}

.contact__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 1rem 1rem 0;
  background: none;
  color: var(--text-color-body);
  border: none;
  outline: none;
  z-index: 1;
}

.contact__label {
  position: absolute;
  top: 0.75rem;
  width: 100%;
  font-size: var(--small-font-size);
  color: var(--text-color-light);
  transition: 0.3s;
  text-align: center;
}

.contact__area {
  height: 7rem;
}

.contact__area textarea {
  resize: none;
}

.contact__input:focus + .contact__label {
  top: -0.75rem;
  left: 0;
  font-size: var(--smaller-font-size);
  z-index: 10;
}

.contact__input:not(:placeholder-shown).contact__input:not(:focus)
  + .contact__label {
  top: -0.75rem;
  font-size: var(--smaller-font-size);
  z-index: 10;
}

.contact__form {
  margin-bottom: 4rem;
}

.contact__button {
  display: flex;
  justify-content: center;
}

.contact__location {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact__directions {
  font-weight: lighter;
  font-size: 1rem;
}

.contact__images {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.contact__image {
  width: 300px;
}

.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.footer__content {
  background-color: #1d1b1b;
}
.footer__light-bg {
  background-color: #4e4d4d;
  padding: 1rem;
}

.footer__socials {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer__socials img {
  width: 40px;
  height: 40px;
  border-radius: 360px;
}

.footer__social-link {
  transition: 0.4s;
  color: var(--text-color-home);
}

.footer__social-link:hover {
  color: var(--text-color-light);
}

.footer__social-link {
  font-size: 1.25rem;
}

.social__links {
  display: inline-flex;
  column-gap: 4rem;
}

.footer__copy {
  color: var(--text-color-home);
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.google__form {
  display: flex;
  justify-content: center;
}

.location {
  display: flex;
  justify-content: center;
  padding: 0 2rem 0 2rem;
}

.location a {
  display: none;
}

.location iframe {
  border: none;
  height: 300px;
}

@media screen and (min-width: 768px) {
  body {
    margin: 0;
  }

  .nav {
    height: calc(var(--header-height) + 1.5rem);
  }

  .nav__link {
    text-transform: initial;
  }

  .nav__link:hover {
    color: var(--text-color-light);
  }

  .nav__menu {
    display: flex;
    column-gap: 4rem;
  }

  .nav__list {
    flex-direction: row;
    column-gap: 2rem;
  }

  .nav__toggle,
  .nav__close {
    display: none;
  }

  .scroll-header .nav__link {
    color: var(--text-color-body);
  }

  .section {
    padding: 7rem 0 2rem;
  }

  .section__title {
    margin-top: 2rem;
  }

  .container {
    margin-left: var(--mb-2);
    margin-right: var(--mb-2);
  }

  .colored-link {
    color: var(--text-color-body);
  }

  .welcome__container {
    margin-top: 10rem;
  }

  .welcome__info {
    padding: 4rem;
    right: 10%;
  }

  .welcome__info h3 {
    font-size: var(--h2-font-size);
  }

  .welcome__info p {
    font-size: var(--normal-font-size);
  }

  .about__content-1,
  .about__content-2 {
    flex-direction: row;
  }

  .about__content-2 {
    flex-direction: row-reverse;
  }

  .about__image {
    width: 300px;
  }

  .about__image-2 {
    width: 250px;
  }

  .study__posts {
    grid-template-columns: 1fr 1fr;
  }

  .study__post {
    justify-self: center;
  }

  .contact__title {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .contact__data {
    text-align: center;
  }

  form {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .contact__images {
    padding: 0 3rem 3rem 3rem;
  }

  .contact__image {
    width: 500px;
  }

  .location iframe {
    height: 400px;
    width: 500px;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }

  .welcome__info h3 {
    font-size: var(--h3-font-size);
  }

  .welcome__info p {
    font-size: var(--small-font-size);
  }

  .about__content-1,
  .about__content-2 {
    padding: 2rem 6rem 0 6rem;
  }

  .about__content-2 {
    flex-direction: column-reverse;
  }

  .about__image {
    width: 350px;
  }

  .contact__image {
    width: 525px;
  }

  .location iframe {
    width: 525px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1024px;
  }
}

@media screen and (max-height: 700px) {
  .welcome__info {
    display: none;
  }
}
